import { getCurrentInstance, reactive, toRefs, ref, onBeforeMount } from "vue";
import { useRouter } from 'vue-router'
import { baseUrlImg } from '@/utils/common'

export default {
	setup() {
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		let dataAll = reactive({
			userData: {},
			userRelationList: ref([]),
			baseUrlImg: baseUrlImg(),
			urlImg: [],
			show: ref(false),
			nullTip: '',
			pageSize: 10, // 每页条数
			pageIndex: 0, // 页码
			dtWinNumberInfos: [], // 请求数据
			isUpLoading: false, // 上拉加载
			upFinished: false, // 上拉加载完毕
			isDownLoading: false, // 下拉刷新
			offset: 100 // 滚动条与底部距离小于 offset 时触发load事件
		});

		const getTSRelations= () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getEquipmentStatic`),
				method: 'post',
				data: null,
				// headers: {
				// 	token: sessionStorage.getItem("userToken")
				// }
			}).then(({
				data
			}) => {
				console.log(data);
				if (data && data.code === 0) {
					const array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].facility_task_code + " - " + data.type[i].facility_task_name,
							id: data.type[i].facility_task_id
						});
					}
					dataAll.userRelationList = array;
					// dataAll.defaultIndex = dataAll.userRelationList.findIndex((ace) => ace.id == dataAll.userRelation);
					// dataAll.userRelationShow = true;
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		} 

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};
		
		const onLoad = () => {
			console.log(dataAll.isDownLoading);
			if (dataAll.isDownLoading) {
				dataAll.dtWinNumberInfos = [];
				dataAll.isDownLoading = false;
				dataAll.pageIndex = 1;
			} else {
				dataAll.pageIndex++;
			}

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/userPage`),
				method: 'post',
				data: proxy.$http.adornData({
					'enterpriseQrNumber': proxy.$router.currentRoute.value.params.id,
					'page': dataAll.pageIndex,
					'limit': dataAll.pageSize
				})
			}).then(({
				data
			}) => {
				console.log(data.data.list);
				if (data && data.code === 0) {
					dataAll.dtWinNumberInfos.push(...data.data.list);
					if (data.data.currPage >= data.data.totalPage) {
						dataAll.upFinished = true;
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
						});
					} else {
						dataAll.isUpLoading = false;
					}
				} else {
					proxy.$toast(data.msg);
					dataAll.isUpLoading = true;
				}
			})
		};

		const onRefresh = () => {
			// 清空列表数据
			dataAll.upFinished = false;
			// 重新加载数据
			// 将 isUpLoading 设置为 true，表示处于加载状态
			dataAll.isUpLoading = true;
			onLoad();
		};

		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}
		const getTSRelationName = (facTaskId) =>{
			let aprroveUnit = {}
			if(facTaskId) {
				aprroveUnit = dataAll.userRelationList.find(item => item.id==facTaskId)
			}
			return aprroveUnit?aprroveUnit.text : '暂无'
		}
		const fan = () => {
			router.push({
				path: "/enterpriseJump/" + sessionStorage.getItem("enterpriseQrNumber")
			});
		}
		
		onBeforeMount(() => { getTSRelations() })
		
		return {
			...toRefs(dataAll),
			getTSRelations,
			onLoad,
			onRefresh,
			showPopup,
			isNull,
			getTSRelationName,
			fan
		}
	}
}
