<template>
	<div class="user">
		<div class="top-title1">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>从业人员信息</span>
		</div>

		<div class="top-title">
			<img src="../img/title_img.png" />
			<span>从业人员信息</span>
		</div>

		<van-pull-refresh v-model="isDownLoading" @refresh="onRefresh" class="pt0px">
			<div v-for="(item,index) in dtWinNumberInfos" :key="index" class="list-user">
				<div class="content-all">
					<div class="content-t1">
						<div class="content-a">{{index+1}}</div>
						<div class="content-c1">&lt; 从业人员 &gt;</div>
					</div>
				</div>

				<div class="content content-flex">
					<div class="content-f">
						<div class="content-t">姓名</div>
						<div class="content-c">{{isNull(item.userName)}}</div>
					</div>
					<div class="content-f">
						<div class="content-t">性别</div>
						<div class="content-c">
							<span v-if="item.userSex==0">女</span>
							<span v-else-if="item.userSex==1">男</span>
							<span v-else>未知</span>
						</div>
					</div>
				</div>

				<div class="content">
					<div class="content-t">身份证号码</div>
					<div class="content-c">{{isNull(item.userNumber)}}</div>
				</div>

				<div class="content content-flex">
					<div class="content-f">
						<div class="content-t">
							<span v-if="item.userCardType==0">健康证编号</span>
							<span v-if="item.userCardType==1">药师资格证编号</span>
							<span v-if="item.userCardType==2">项目代号</span>
						</div>
						<div class="content-c">{{isNull(item.userCardNumber)}}</div>
					</div>

					<div class="content-f">
						<div class="content-t">有效日期</div>
						<div v-if="item.userCardType!=2" class="content-c">{{isNull(item.userCardEtime)}}</div>
						<div v-else>{{isNull(item.userCardStime)}}至{{isNull(item.userCardEtime)}}</div>
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==1">
					<div class="content-f">
						<div class="content-f">
							<div class="content-t">注册证编号</div>
							<div class="content-c">{{isNull(item.userRegisterNumber)}}</div>
						</div>
					</div>
					<div class="content-f">
						<div class="content-t">注册日期</div>
						<div class="content-c">{{isNull(item.userRegisterTime)}}</div>
					</div>
				</div>

				<div class="content">
					<div class="content-t">
						<span v-if="item.userCardType==0">健康证照片</span>
						<span v-if="item.userCardType==1">药师资格证照片</span>
						<span v-if="item.userCardType==2">特种设备操作证照片</span>
					</div>
					<div class="content-c">
						<span v-if="!item.userCardSrc">暂无</span>
						<img v-else @click="showPopup(`${baseUrlImg}${item.userCardSrc}`)" :src="`${baseUrlImg}${item.userCardSrc}`" />
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==1">
					<div class="content-f">
						<div class="content-t">执业地区</div>
						<div class="content-c">
							<div>{{isNull(item.userCardAddress)}}</div>
						</div>
					</div>
					<div class="content-f">
						<div class="content-t">执业范围</div>
						<div class="content-c">
							<span>{{isNull(item.userCardRange)}}</span>
						</div>
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==1">
					<div class="content-f">
						<div class="content-t">执业类型</div>
						<div class="content-c">
							<div>{{isNull(item.pharmacistName)}}</div>
						</div>
					</div>
					<div class="content-f">
						<div class="content-t">执业单位</div>
						<div class="content-c">
							<span>{{isNull(item.enterpriseName)}}</span>
						</div>
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==2">
					<div class="content-f">
						<div class="content-t">特设证书类型</div>
						<div class="content-c">
							<div>{{ getTSRelationName(item.userRelation) }}</div>
						</div>
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==2">
					<div class="content-f">
						<div class="content-t">发证机关</div>
						<div class="content-c">
							<div>{{isNull(item.userCardOffice)}}</div>
						</div>
					</div>
				</div>

				<div class="content content-flex" v-if="item.userCardType==2">
					<div class="content-f">
						<div class="content-t">所属单位</div>
						<div class="content-c">
							<div>{{isNull(item.enterpriseName)}}</div>
						</div>
					</div>
				</div>
			</div>
			<van-list v-model:loading="isUpLoading" :finished="upFinished" :finished-text="nullTip" @load="onLoad" />
		</van-pull-refresh>
		
		<van-image-preview :closeable="true" :show-index="false" v-model:show="show" :images="urlImg"></van-image-preview>
	</div>
</template>

<style scoped>
	.content-all {
		width: 100%;
		height: 1rem;
		background-color: #0475d3;
		color: white;
	}

	.content-t1 {
		width: 94%;
		margin-left: 3%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.373333rem;
		position: relative;
	}

	.content-a {
		position: absolute;
		top: 0.13rem;
		width: 0.666667rem;
		text-align: center;
		height: 0.666667rem;
		line-height: 0.666667rem;
		background-color: white;
		color: #000;
		border-radius: 0.33333rem;
		font-size: 0.4rem;
	}

	.content-c1 {
		width: 90%;
		margin-left: 10%;
	}

	.top-title {
		background-color: #eae6e5;
		color: #232122;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: relative;
		padding-top: 1.173333rem;
	}

	.top-title1 {
		background-color: #025193;
		color: white;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 100;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		left: 0.133333rem;
		color: white;
		top: 0.24rem;
		font-size: 0.746667rem;
	}

	.top-title span {
		font-size: 0.48rem;
		font-weight: 550;
		letter-spacing: 0.026667rem;
		margin-left: 0.933333rem;
	}

	.top-title img {
		height: 0.533333rem;
		position: absolute;
		top: 1.463333rem;
		left: 0.266667rem;
	}

	body {
		background-color: #eae6e5;
	}

	.pt0px {
		padding-top: 0rem;
		background-color: #eae6e5;
	}

	.list-user {
		background-color: #fff;
		width: 100%;
		margin-bottom: 0.4rem;
	}

	.content {
		width: 94%;
		margin-left: 3%;
		border-bottom: 1px #eeeeee solid;
	}

	.content-t {
		color: #2a8df0;
		font-size: 0.373333rem;
		padding-top: 0.186667rem;
		width: 100%;
		text-indent: 0.133333rem;
	}

	.content-c {
		color: #464646;
		font-size: 0.373333rem;
		padding-bottom: 0.186667rem;
		margin-top: 0.053333rem;
		text-indent: 0.133333rem;
		width: 100%;
	}

	.content-c1 {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.content-flex {
		display: flex;
	}

	.content-f {
		flex: 1;
	}

	.content-c img {
		height: 1.973333rem;
		padding: 0.133333rem 0.133333rem 0rem 0.133333rem;
	}

	::v-deep.van-popup__close-icon {
		color: #2a8df0;
	}
</style>

<script>
	import User from "../js/User";
	export default {
		...User,
	};
</script>
